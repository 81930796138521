var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {}, [_c('div', {
    staticClass: "__top_navigation"
  }, [_c('div', {
    staticClass: "nav_container"
  }, [_c('button', {
    class: [_vm.$route.path == '/developer/api_v1' ? 'active' : ''],
    on: {
      "click": function ($event) {
        return _vm.toggleComponent('/developer/api_v1');
      }
    }
  }, [_vm._v(" API v.1.0 ")]), _c('button', {
    class: [_vm.$route.path == '/developer/api_v2' ? 'active' : ''],
    on: {
      "click": function ($event) {
        return _vm.toggleComponent('/developer/api_v2');
      }
    }
  }, [_vm._v(" API v.2.0 ")])])]), _c('div', [_c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };