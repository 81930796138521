import "core-js/modules/es.array.push.js";
import Version1 from './version1.vue';
import Version2 from './version2.vue';
import Storage from '../../../services/storage.js';
let storage = new Storage();
export default {
  name: 'developer',
  components: {
    Version1,
    Version2
  },
  data() {
    return {
      // currentComponent: 'Version2'
    };
  },
  methods: {
    toggleComponent(url) {
      if (this.$route.path !== url) {
        this.$router.push(url).catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
    }
  },
  computed: {},
  mounted() {}
};